import { Injectable } from '@angular/core';
import {ConfigService} from './config.service';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {MessageService} from './message.service';
import {Observable, throwError} from 'rxjs';
import {catchError, retry} from 'rxjs/operators';

export interface Contact {
  name: string;
  email: string;
  message: string;
}

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  private serviceUrl: string;

  constructor(private config: ConfigService,
              private http: HttpClient,
              private messageService: MessageService) {
    this.serviceUrl = config.data.contact.url;
  }

  contact (data: Contact): Observable<any> {
    // TODO ensure this.serviceUrl is defined
    return this.http.post<any>(this.serviceUrl, data, httpOptions)
      .pipe(
        retry(2), // retry a failed request up to 2 times
        //tap(_ => this.log('Fetched contact query=${query}')),
        catchError(error => this.handleError(error))
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param error - name of the operation that failed
   */
  private handleError(error: HttpErrorResponse) {
    let message;
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      message = error.error.message;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      message = `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`;
    }
    console.error(message);
    this.log(message, 'error');
    // return an observable with a user-facing error message
    return throwError(error);
  }

  private log(message: string, level: string = 'info') {
    this.messageService.add(`${message}`, 'contact', level);
  }
  
}
