import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {HomePageComponent} from "./components/pages/home/home.component";
import {ContactPageComponent} from './components/pages/contact/contact.component';

const routes: Routes = [
  { path: '', component: HomePageComponent },
  //{ path: 'contact', component: ContactPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
