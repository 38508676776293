import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from "../services/translate.service";

/**
 * Make headers compliant to fonts' styling
 */
@Pipe({
  name: 'translate',
  //pure: false
  /** We do not use impure because it impacts performance.
   Instead we added one parameter to the pipe (language of the TranslateService).
   The parameter change trigger the pipe to refresh just once.

   Usage example:
   1. Component
   export class AboutPageComponent implements OnInit {
           constructor(
              public ts: TranslateService
            ) { }
         }
   2. Template
   {{ 'KEY' | translate:ts.lang }}

   3. Default value
   By default, if the translation not found, the key is displayed.
   {{ 'SOME_KEY' | translate:ts.lang }} -> "SOME_KEY" is displayed
   If it is not desired to displays the key as default value, custom default value can be provided
   {{ 'SOME_KEY' | translate:ts.lang:'Custom Value' }} -> "Custom Value" is displayed

   */
})
export class TranslatePipe implements PipeTransform {

  constructor(private ts: TranslateService) {}

  transform(key: any, lang: string, defaultValue: string = null) {
    //TODO lang parameter is currently not considered for translation and current value of TranslateService is always used instead.
    return this.ts.data[key] || (defaultValue!==null ? defaultValue : key);
  }
}
