import {Component, HostListener, OnInit} from '@angular/core';
import { TranslateService } from "../../../services/translate.service";
import {ConfigService} from '../../../services/config.service';
import {Subscription} from 'rxjs';
import {ContentService, EntryCollection} from '../../../services/content.service';
import {HttpParams} from '@angular/common/http';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomePageComponent implements OnInit {

  public entries: object = {};
  private subscriptions: Subscription[] = [];
  public screenHeight = window.innerHeight;
  public screenWidth = window.innerWidth;

  constructor(
    public ts: TranslateService,
    public config: ConfigService,
    private contentService: ContentService
  ) {

    this.subscriptions.push(ts.watch.asObservable().subscribe(lang => this.getEntries(lang)));
  }

  ngOnInit() {
    this.getEntries(this.ts.lang);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription)=>subscription.unsubscribe())
  }

  getEntries(lang): void {
    let path = `content/${lang}/home.json`; //TODO the path is temporary
    let query = new HttpParams();
    query = query.append('lang', lang);
    query = query.append('screenHeight', ''+this.screenHeight);
    query = query.append('screenWidth', ''+this.screenWidth);
    this.contentService.getEntries(query, path)
      .subscribe(content => this.classifyEntries(content.entries));
  }

  classifyEntries(entries: any[] = []): void {
    this.entries = {
      intro: entries.filter(entry => entry.contexts.indexOf('intro')>-1),
      carousel: entries.filter(entry => entry.contexts.indexOf('carousel')>-1)
    }
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: object) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

}
