import { Component, OnInit } from '@angular/core';
import {TranslateService} from '../../../services/translate.service';
import {ConfigService} from '../../../services/config.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  contactFormIsActive: boolean = false;
  mailto: string;

  constructor(
    private config: ConfigService,
    public ts: TranslateService
  ) {
    this.mailto = config.data.contact.email ? 'mailto:'+config.data.contact.email : '';
  }

  ngOnInit() {
  }

  toggleContactForm() {
    this.contactFormIsActive = !this.contactFormIsActive;
  }

}
