import { Injectable } from '@angular/core';

//TODO allow subscribing to messages

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  private messages = [];
  private defaultTopic = 'general';

  constructor() { }

  add(message: string, topic?: string, level: string = 'info') {
    if(['info','warning','error'].indexOf(level)<0){
      level = 'info'
    }
    if(!topic){
      topic = this.defaultTopic;
    }
    this.messages.push({
      topic: topic,
      timestamp: (new Date()).getTime(),
      message: message,
      level: level
    });
  }

  clear(topic) {
    this.messages = this.messages.filter(message => message.topic != topic);
  }

  clearAll() {
    this.messages = [];
  }
}
