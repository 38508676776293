import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/elements/header/header.component';
import { FooterComponent } from './components/elements/footer/footer.component';
import { HomePageComponent } from './components/pages/home/home.component';
import { TranslatePipe } from "./adapters/translate.pipe";
import { TranslateService } from "./services/translate.service";
import { ConfigService } from './services/config.service';
import { LanguageFormComponent } from './components/forms/language/language.component';
import { CarouselComponent } from './components/elements/carousel/carousel.component';
import { ContactPageComponent } from './components/pages/contact/contact.component';
import { ContactFormComponent } from './components/forms/contact-form/contact-form.component';

export function setupTranslateFactory(service: TranslateService): Function {
  // TODO decide to use default language from user browser settings
  let lang = 'en';
  // TODO start loader
  return () => service.usep(lang)
    .then(
      (data: any) => {
        console.log('APP_INITIALIZER: TranslateService resolve');
      },
      (error: any) => {
        console.error('APP_INITIALIZER: TranslateService reject', error);
      }
    )
    .finally(() => {
      // TODO stop loader
    })
}

export function setupConfigFactory(service: ConfigService): Function {
  // TODO start loader
  return () => service.loadp()
    .then(
      (data: any) => {
        console.log('APP_INITIALIZER: ConfigService resolve');
      },
      (error: any) => {
        console.error('APP_INITIALIZER: ConfigService reject', error);
      }
    )
    .finally(() => {
      // TODO stop loader
    })
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomePageComponent,
    TranslatePipe,
    LanguageFormComponent,
    CarouselComponent,
    ContactPageComponent,
    ContactFormComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule
  ],
  providers: [
    TranslateService,
    {
      provide: APP_INITIALIZER,
      useFactory: setupTranslateFactory,
      deps: [ TranslateService ],
      multi: true
    },
    ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: setupConfigFactory,
      deps: [ ConfigService ],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
