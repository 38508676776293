import { Component, OnInit } from '@angular/core';
import {ConfigService} from '../../../services/config.service';
import {TranslateService} from '../../../services/translate.service';
import {MessageService} from '../../../services/message.service';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss']
})
export class LanguageFormComponent implements OnInit {

  public languages: object[];

  constructor(
    public ts: TranslateService,
    public config: ConfigService,
    private messageService: MessageService) {

    const compare = (a, b) => {
      let _a = a.sort || 0, _b = b.sort || 0;

      if (_a < _b) {
        return -1;
      }
      if (_a > _b) {
        return 1;
      }
      return 0;
    };
    //Get sorted and enabled languages from application config
    this.languages = this.config.data.languages.sort(compare).filter(language => {
      return (language.enabled || typeof language.enabled === 'undefined')
    })
  }

  ngOnInit() {}

  onLanguageChange(event) {
    let prevLang = this.ts.lang,
        lang = event.target.value;

    this.ts.use(lang)
      .subscribe(
        data => {
          this.log(`App languages has been successfully changed from ${prevLang} to ${lang}`);
        },
        error => {
          this.log(`Could not load translations for the selected language ${lang}`);
        }
      );
  }

  private log(message: string, level: string = 'info') {
    this.messageService.add(`${message}`, 'user.context', level);
  }

}
