import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import { MessageService } from './message.service';
import { environment } from "../../environments/environment";

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  configUrl = environment.configUrl;
  data: any = {};

  constructor(
    private http: HttpClient,
    public messageService: MessageService) {
  }

  load(): Observable<{}> {
    return new Observable<{}>((observer) => {
      this.getConfig()
        .subscribe(
          data => {
            this.data = data;
            observer.next(data);
            observer.complete();
          },
          error => {
            observer.error(error);
            observer.complete();
          }
        );
    });
  }

  loadp(): Promise<{}> {
    return new Promise<{}>((resolve, reject) => {
      this.getConfig()
        .subscribe(
          data => {
            this.data = data;
            resolve(data);
          },
          error => {
            // TODO decide maybe not to reject but to bootstrap with some fallback data
            // When used with APP_INITIALIZER, the reject will cause app to be not loaded
            reject(error);
          }
        );
    });
  }

  private getConfig(): Observable<{}> {
    const path = `${this.configUrl}`;
    return this.http.get<{}>(path, httpOptions)
      .pipe(
        //tap(_ => this.log(`Fetched translation ${lang}`)),
        catchError(error => this.handleError(error))
      );
  }

  private handleError(error: HttpErrorResponse) {
    let message;
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      message = error.error.message;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      message = `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`;
    }
    console.error(message);
    this.log(message, 'error');
    // return an observable with a user-facing error message
    return throwError(
      'Fatal Error; Could not load application config!');
  };

  private log(message: string, level: string = 'info') {
    this.messageService.add(`${message}`, 'config', level);
  }
}
