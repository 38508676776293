import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {MessageService} from './message.service';
import {Observable, of, throwError} from 'rxjs';
import {catchError, retry} from "rxjs/operators";
import {ConfigService} from './config.service';

export interface EntryCollection {
  total: number;
  entries: any[];
}

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  private serviceUrl: string;
  httpHeaders = {};

  constructor(private config: ConfigService,
              private http: HttpClient,
              private messageService: MessageService) {
    this.serviceUrl = config.data.content.url;

    let headers = Object.keys(config.data.content.headers || {}).reduce(
      (accumulator: any, key: string)=>{
        accumulator[key] = config.data.content.headers[key];
        return accumulator;
      }, {'Content-Type': 'application/json'});
    this.httpHeaders = new HttpHeaders(headers);
  }

  getEntries (params?: HttpParams, path?: string): Observable<EntryCollection> {
    let httpOptions = {
      headers: this.httpHeaders,
      params: {}
    };
    let url = this.serviceUrl;
    if(path){
      url = `${url}/${path.replace(/^\//,'')}`;
    }
    if(params){
      httpOptions.params = params;
    }
    // TODO Cache response
    return this.http.get<EntryCollection>(url, httpOptions)
      .pipe(
        retry(2), // retry a failed request up to 2 times
        //tap(_ => this.log('Fetched content query=${query}')),
        catchError(error => this.handleError(error))
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param error - name of the operation that failed
   */
  private handleError(error: HttpErrorResponse) {
    let message;
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      message = error.error.message;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      message = `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`;
    }
    console.error(message);
    this.log(message, 'error');
    // return an observable with a user-facing error message
    return throwError(error);
  }

  private log(message: string, level: string = 'info') {
    this.messageService.add(`${message}`, 'content', level);
  }

}
