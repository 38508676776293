import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {TranslateService} from '../../../services/translate.service';
import {ContactService} from '../../../services/contact.service';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {

  isSubmitted: boolean = false;
  submitError: boolean = false;
  emailPattern: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  contactForm = new FormGroup({
    name: new FormControl(''),
    email: new FormControl('', [
      Validators.required,
      Validators.pattern(this.emailPattern)
    ]),
    message: new FormControl('', [
      Validators.required
    ])
  });

  constructor(
    private contactService: ContactService,
    public ts: TranslateService
  ) { }

  ngOnInit() {
  }

  onSubmit() {
    this.isSubmitted = true;

    this.contactService.contact(this.contactForm.value)
      .subscribe(
        response => {
          //TODO show success
          this.reset();
        },
        error => {
          //TODO show error
          this.submitError = true;
        }
      );
  }

  reset(){
    this.isSubmitted = false;
    this.submitError = false;
    this.contactForm.reset();
  }

}
