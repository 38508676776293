import {Component, HostListener, Input, OnInit} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import {ConfigService} from '../../../services/config.service';
import {ContentService} from '../../../services/content.service';
import {TranslateService} from '../../../services/translate.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  providers: [ NgbCarouselConfig ]
})
export class CarouselComponent implements OnInit {

  @Input() entries: object[];

  constructor(private config: ConfigService,
    carouselConfig: NgbCarouselConfig
  ) {
    Object.keys(config.data.carousel).forEach(function (key) {
      carouselConfig[key] = config.data.carousel[key];
    });
  }

  ngOnInit() {
  }

}
